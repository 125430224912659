import React, { FC } from 'react';
import { graphql, navigate } from 'gatsby';
import { CTADigitalFragment, DigitalPageFragment, DigitalPageQuery } from '../entities/operationResults';
import Meta from './atoms/Meta';
import withDefaultTransition from '../hocs/withDefaultTransition';
import loadable from '@loadable/component';

const DigitalWizard = loadable(() => import('./organisms/DigitalWizard'));

export const query = graphql`
    query DigitalPageQuery($uri: String) {
        craft {
            entry(uri: $uri) {
                ...DigitalPageFragment
            }
            entries(type: CTADigital) {
                ...CTADigitalFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment DigitalPageFragment on Craft_Digital {
        title
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    data: DigitalPageQuery;
}

const Digital: FC<Props> = ({ data }) => {
    const cta: CTADigitalFragment = data.craft?.entries?.[0] as CTADigitalFragment;
    const entry = data.craft.entry as DigitalPageFragment;
    const meta = entry.seo?.[0];

    return (
        <>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : entry.title}
                description={meta && meta.seoDescription ? meta.seoDescription : null}
                image={meta && meta.seoImage ? meta.seoImage : null}
            />

            <DigitalWizard data={cta} showPopover setShowPopover={setShowPopover} variant="light" withDelay />
        </>
    );
};

const setShowPopover = (value: boolean) => {
    if (!value) {
        navigate('/');
    }
};

export default withDefaultTransition(Digital);
